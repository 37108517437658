import axios from "axios";

export const CUSTOMERS_URL = "admins";

// CREATE =>  POST: add a new admin to the server
export function createAdmin(admin) {
    let paramsArray = JSON.stringify(admin);
  return axios.post(`${CUSTOMERS_URL}/create`, { paramsArray });
  //return axios.post(CUSTOMERS_URL, { admin });
}

// READ
export function getAllAdmins() {
    console.log('get admins');
  return axios.get(CUSTOMERS_URL);
}

export function getAdminById(adminId) {
  return axios.get(`${CUSTOMERS_URL}/${adminId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findAdmins(queryParams) {
    console.log('get admins');
    let paramsArray = JSON.stringify(queryParams);
  return axios.post(`${CUSTOMERS_URL}`, { paramsArray });
}

// UPDATE => PUT: update the admin on the server
export function updateAdmin(admin) {
    let paramsArray = JSON.stringify(admin);
  return axios.put(`${CUSTOMERS_URL}/${admin.id}`, { paramsArray });
}

// UPDATE Status
export function updateStatusForAdmins(ids, status) {
    let paramsArray = JSON.stringify({ids,status});
  return axios.post(`${CUSTOMERS_URL}/updateStatusForAdmins`, { paramsArray });
}

// DELETE => delete the admin from the server
export function deleteAdmin(adminId) {
  return axios.delete(`${CUSTOMERS_URL}/${adminId}`);
}

// DELETE Admins by ids
export function deleteAdmins(ids) {
    let paramsArray = JSON.stringify({ids});
  return axios.post(`${CUSTOMERS_URL}/deleteAdmins`, { paramsArray });
}
