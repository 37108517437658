import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect,useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { login } from "../_redux/authCrud";
import * as actions from "../../Admins/_redux/adminsActions";
import queryString from 'query-string';
import { verifyAdmin } from "../_redux/authCrud";
import { updateAdmin } from "../../Admins/_redux/adminsCrud";
import axios from "axios";
import * as auth from "../_redux/authRedux";

const initialValues = {
  password: "",
  confirmPassword:  ""
};

function Register(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const SignupSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
      confirmPassword: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  let {invite_token} =  queryString.parse(props.location.search)
  const [user, setUser] = useState({});
  const [id, setId] = useState("");
  const [authToken, setAuthToken] = useState("");
  const dispatch = useDispatch();
  
  useEffect(() => {
    const fetchUserVerifyAPI = async () => {
      props.logout();
      let {data} = await verifyAdmin(invite_token);
      if(data.message != "Admin Account Approved Successfully"){
        props.history.push("/error");
      } else {
        setUser(data.data);
        setId(data.data.id);
        setAuthToken(data.token);
      }
    };
    fetchUserVerifyAPI();
    dispatch(actions.fetchAdmin(id));
  }, [id,dispatch]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(async() => {
        values.id = user.id;
        const headers = { 
          'Authorization': 'Bearer ' + authToken,
        };
        values.invite_token = invite_token;
        let paramsArray = JSON.stringify(values);
        let {data} = await axios.post(`updateProfile`, { paramsArray } ,{ headers })
        console.log("result", data);
        if(data){
          props.login(authToken /*accessToken*/);
          if(data.message == "Profile Updated Successfully") {
            props.history.push("/");
          }
        }
      }, 1000);
    },
  });

  const saveAdmin = async (admin) => {
    console.log("admin",admin);
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Complete Profile
        </h3>
        <p className="text-muted font-weight-bold">
          Set your password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          ''
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "confirmPassword"
            )}`}
            name="confirmPassword"
            {...formik.getFieldProps("confirmPassword")}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.confirmPassword}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign Up</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Register));
