import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {adminsSlice} from "../app/modules/Admins/_redux/adminsSlice";
import {driversSlice} from "../app/modules/Admin/_redux/drivers/driversSlice";
import {vehiclesSlice} from "../app/modules/Admin/_redux/vehicles/vehiclesSlice";
import {vehicletypesSlice} from "../app/modules/Admin/_redux/vehicletypes/vehicletypesSlice";
import {drivervehiclesSlice} from "../app/modules/Admin/_redux/drivervehicles/drivervehiclesSlice";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {driverdocsSlice} from "../app/modules/Admin/_redux/driverdocs/driverdocsSlice";
import {vehicledocsSlice} from "../app/modules/Admin/_redux/vehicledocs/vehicledocsSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  admins: adminsSlice.reducer,
  drivers: driversSlice.reducer,
  vehicles: vehiclesSlice.reducer,
  vehicletypes: vehicletypesSlice.reducer,
  drivervehicles: drivervehiclesSlice.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  driverdocs: driverdocsSlice.reducer,
  vehicledocs: vehicledocsSlice.reducer,
  specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
