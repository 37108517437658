// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../_metronic/_partials/controls";
import {
  AVAILABLE_GENDERS,
  DriverStatusTitles,
  YESNO_OPTIONS,
} from "../DriversUIHelpers";

// Validation schema
const DriverEditSchema = Yup.object().shape({
  model: Yup.string()
    .min(2, "Minimum 2 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Model is required"),
  manufacture: Yup.string()
    .min(2, "Minimum 2 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Manufacture is required"),
  modelYear: Yup.number()
    .min(1950, "1950 is minimum")
    .max(2020, "2020 is maximum")
    .required("Model year is required"),
  mileage: Yup.number()
    .min(0, "0 is minimum")
    .max(1000000, "1000000 is maximum")
    .required("Mileage is required"),
  color: Yup.string().required("Color is required"),
  price: Yup.number()
    .min(1, "$1 is minimum")
    .max(1000000, "$1000000 is maximum")
    .required("Price is required"),
  VINCode: Yup.string().required("VINCode is required"),
});

export function DriverEditForm({
  driver,
  btnRef,
  saveDriver,
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={driver}
        validationSchema={DriverEditSchema}
        onSubmit={(values) => {
          saveDriver(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    name="firstName"
                    component={Input}
                    placeholder="First Name"
                    label="First Name"
                  />
                </div>
                <div className="col-lg-4">
                <Field
                  name="lastName"
                  component={Input}
                  placeholder="First Name"
                  label="First Name"
                />
                </div>
                <div className="col-lg-4">
                  <Field
                    type="email"
                    name="email"
                    component={Input}
                    placeholder="Email"
                    label="Email"
                  />
                </div>
              </div>
              <div className="form-group row">
              <div className="col-lg-4">
                <Field
                  name="phone"
                  component={Input}
                  placeholder="Phone"
                  label="Phone Number"
                  customFeedbackLabel="Please enter Driver Phone Number"
                />
              </div>
              <div className="col-lg-4">
                  <Field
                    type="password"
                    name="password"
                    component={Input}
                    placeholder="Password"
                    label="Password"
                  />
                </div>
                <div className="col-lg-4">
                  <Select name="gender" label="Gender">
                    {AVAILABLE_GENDERS.map((gender) => (
                      <option key={gender} value={gender}>
                        {gender}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    name="wallet_balance"
                    component={Input}
                    placeholder="Wallet Balance"
                    label="Wallet Balance"
                  />
                </div>
                <div className="col-lg-4">
                  <Select name="status" label="Status">
                    {DriverStatusTitles.map((status, index) => (
                      <option key={status} value={index}>
                        {status}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="col-lg-4">
                  <Select name="accept_cash" label="Can Accept Cash">
                    {YESNO_OPTIONS.map((condition, index) => (
                      <option key={condition} value={index}>
                        {condition}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    name="paymentEmail"
                    type="email"
                    component={Input}
                    placeholder="Payment Email"
                    label="Payment Email"
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    name="bankAccountHolderName"
                    component={Input}
                    placeholder="Bank Account Holder Name"
                    label="Bank Account Holder Name"
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    name="accountNumber"
                    component={Input}
                    placeholder="Bank Account Number"
                    label="Bank Account Number"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    name="bankName"
                    component={Input}
                    placeholder="Bank Name"
                    label="Bank Name"
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    name="bankLocation"
                    component={Input}
                    placeholder="Bank Location"
                    label="Bank Location"
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    name="bankSwiftCode"
                    component={Input}
                    placeholder="Bank Swift Code"
                    label="Bank Swift Code"
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Description</label>
                <Field
                  name="profileDescription"
                  as="textarea"
                  className="form-control"
                />
              </div>
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
