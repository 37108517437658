import React, {useEffect } from "react";
import {useSubheader} from "../../_metronic/layout";

export function Privacy() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Terms and Conditions");

  useEffect(() => {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
  });

  return (<>
      <h1>Allez Privacy Notice</h1>

      <h3>I. Introduction</h3>
      <p>When you use Allez, you trust us with your personal data. We’re committed to keeping that trust. That starts with helping you understand our privacy practices.</p>
      <p>This notice describes the personal data we collect, how it’s used and shared, and your choices regarding this data. We recommend that you read this along with our privacy overview, which highlights key points about our privacy practices.</p>
      <p>Last modified: December 2, 2020</p>
      <p>Effective date: December 2, 2020</p>

      <h3>II. Overview</h3>
      <h4>A. Scope</h4>
      <p>This notice applies to users of Allez’s services anywhere in the world, including users of Allez’s apps, websites, features, or other services.</p>
      <p>This notice describes how Allez and its affiliates collect and use personal data. This notice applies to all users of our apps, websites, features, or other services anywhere in the world, unless covered by a separate privacy notice, such as the Allez Freight Privacy Notice or Careem Privacy Policy. This notice specifically applies to:</p>
      <ul>
          <li>Riders: individuals who request or receive transportation, including those who receive transportation requested by another individual</li>
          <li>Drivers: individuals who provide transportation to Riders individually or through partner transportation companies</li>
          <li>Delivery recipients: individuals who request or receive food, or other products and services</li>
          <li>Delivery persons: individuals who provide delivery or other services</li>
      </ul>
      <p>This notice also governs Allez’s other collections of personal data in connection with Allez’s services. For example, we may collect the contact information of individuals who use accounts owned by Allez for Business customers or of owners or employees of Allez Eats restaurant partners. We may also collect the personal data of those who start but do not complete applications to be drivers or delivery persons, those who rent bicycles, scooters, or other light electrical devices offered by other companies through an Allez app, or in connection with our mapping technology and features.</p>
      <p>All those subject to this notice are referred to as “users” in this notice.</p>
      <p>In addition, please note the following:</p>
      <ul>
        <li>For Guest Users: The personal data of those who order or receive trips or deliveries via partner websites or apps (such as when ordering from a restaurant or grocery store), or arranged by other account owners (collectively “Guest Users”) may be used for purposes described in this notice, and may be associated with, and accessible by, the owner of that account. This specifically includes Guest Users who receive rides/deliveries ordered by owners of Allez Health, Allez Central or Allez for Business accounts, or who receive rides or deliveries ordered by friends, family members or others. To submit questions, comments or complaints regarding Guest User data, or to submit requests regarding such data, please visit here.</li>
      </ul>
      <p>Our data practices are subject to applicable laws in the places in which we operate. This means that we engage in the practices described in this notice in a particular country or region only if permitted under the laws of those places. Please contact us here or through the addresses below with any questions regarding our practices in a particular country or region.</p>

      <h4>B. Data controller and transfer</h4>
      <p>Allez B.V. and Allez Technologies Inc. are the data controllers for the personal data collected in connection with use of Allez’s services in the European Economic Area and the United Kingdom.</p>
      <p>Allez Technologies Inc. is the data controller for the personal data collected in connection with use of Allez’s services anywhere else.</p>
      <p>Allez B.V. (Mr. Treublaan 7, 1097 DP, Amsterdam, the Netherlands) and Allez Technologies Inc. (1455 Market Street, San Francisco, CA, 94103, USA) are the data controllers for the personal data collected in connection with use of Allez’s services in the European Economic Area, the United Kingdom and Switzerland. If you’re a driver in the UK, the Allez entity holding the relevant PHV operator license is a controller for complying with licensing requirements.</p>
      <p>Allez Technologies Inc. is the data controller for the personal data collected in connection with use of Allez’s services anywhere else.</p>
      <p>Allez operates, and processes data, globally. We may also transfer data to countries other than the one where our users live or use Allez’s services. We do so in order to fulfill our agreements with users, such as our Terms of Use, or based on users’ prior consent, adequacy decisions for the relevant countries, or other transfer mechanisms as may be available under applicable law, such as the Standard Contractual Clauses.</p>
      <p>Questions, comments, and complaints about Allez’s data practices can be submitted here. You may also use this form to submit a question to Allez’s Data Protection Officer.</p>

      <h3>III. Data collections and uses</h3>
      <h4>A. The data we collect</h4>
      <p>Allez collects:</p>
      <ul>
        <li>Data provided by users to Allez, such as during account creation</li>
        <li>Data created during use of our services, such as location, app usage, and device data</li>
        <li>Data from other sources, such as Allez partners and third parties that use Allez APIs</li>
      </ul>
      <h5>The following data is collected by or on behalf of Allez:</h5>
      <p>1- Data provided by users. This includes:</p>
      <ul>
      <li>User profile: We collect data when users create or update their Allez accounts. This may include their name, email, phone number, login name and password, address, profile picture, payment or banking information (including related payment verification information), driver’s license and other government identification documents (which may indicate document numbers as well as birth date, gender, and photo). This also includes vehicle or insurance information of drivers and delivery persons, emergency contact information, user settings, and evidence of health or fitness to provide services using Allez apps.</li>
      <li>This also includes gender and/or occupation (when required for certain Allez services or programs, such as Allez Wallet or Women Rider Preferred).</li>
      <li>We may use the photos submitted by users to verify their identities, such as through facial recognition technologies. For more information, please see the section titled “How we use personal data.”</li>
      <li>Background check and identity verification: We collect background check and identity verification information for drivers and delivery persons. This may include information such as driver history or criminal record (where permitted by law), and right to work. This information may be collected by an authorized vendor on Allez’s behalf. We also collect identity verification from Allez Eats users who request alcohol delivery.</li>
      <li>Demographic data: We may collect demographic data about users, including through user surveys. In some countries, we may also receive demographic data about users from third parties.</li>
      <li>User content: We collect the information users submit when they contact Allez customer support, provide ratings or compliments for other users or restaurant partners, or otherwise contact Allez. This may include feedback, photographs or other recordings collected by users.</li>
      </ul>

      <p>2- Data created during use of our services. This includes:</p>
      <ul>
      <li>Location data (driver and delivery person): Allez collects this data when the Allez app is running in the foreground (app open and on-screen) or background (app open but not on-screen) of their mobile device.</li>
      <li>Location data (riders and delivery recipients). We collect precise or approximate location data from riders’ and delivery recipients’ mobile devices if they enable us to do so. Allez collects this data from the time a ride or delivery is requested until it is finished, and any time the app is running in the foreground of their mobile device. We use this data to enhance your use of our apps, including to improve pick-up locations, enable safety features, and prevent and detect fraud.</li>
      <li>Allez collects driver location data, and links location data collected during a trip with their rider’s account, even if the rider has not enabled us to collect location data from their device. This enables us to offer services to the rider like receipt generation and customer support.</li>
      <li>Transaction information: We collect transaction information related to the use of our services, including the type of services requested or provided, order details, delivery information, date and time the service was provided, amount charged, distance traveled, and payment method. Additionally, if someone uses your promotion code, we may associate your name with that person.</li>
      <li>Usage data: We collect data about how users interact with our services. This includes data such as access dates and times, app features or pages viewed, app crashes and other system activity, type of browser, and third-party sites or services used before interacting with our services. In some cases, we collect this data through cookies, pixels, tags, and similar tracking technologies that create and maintain unique identifiers. To learn more about these technologies, please see our Cookie Notice.</li>
      <li>Device data: We may collect data about the devices used to access our services, including the hardware models, device IP address, operating systems and versions, software, preferred languages, unique device identifiers, advertising identifiers, serial numbers, device motion data, and mobile network data.</li>
      <li>Communications data: We enable users to communicate with each other and Allez through Allez’s mobile apps and websites. For example, we enable drivers and riders, and restaurants or delivery persons and delivery recipients, to call, text, or send other files to each other (generally without disclosing their telephone numbers to each other). To provide this service, Allez receives some data regarding the calls, texts, or other communications, including the date and time of the communications and the content of the communications. Allez may also use this data for customer support services (including to resolve disputes between users), for safety and security purposes, to improve our products and services, and for analytics.</li>
      <li>Rental devices data: We collect data generated by rental devices, such as bicycles, scooters, or other light electric vehicles or devices, when they’re in use. This includes the date and time of use, and the location, route, and distance traveled. To the extent permitted by law, the location data collected from the rental device during the trip will be linked to the renter’s account, even if they have not enabled Allez to collect location data from their mobile device.</li>
      <li>Audio recordings: In certain jurisdictions, and where permitted by law, users can record the audio of their trips through an in-app feature. Recordings are encrypted and stored on users’ devices, and are only shared with Allez if submitted to customer support by the users in connection with safety incidents. Please see here more information.</li>
      </ul>

      <p>3- Data from other sources. This includes:</p>
      <ul>
      <li>User feedback, such as ratings, feedback, or compliments.</li>
      <li>Users participating in our referral programs. For example, when a user refers another person, we receive the referred person’s personal data from that user.</li>
      <li>Allez account owners who request services for or on behalf of other users, or who enable such users to request or receive services through their accounts. This includes owners of Allez for Business accounts.</li>
      <li>Users or others providing information in connection with claims or disputes.</li>
      <li>Allez business partners through which users create or access their Allez account, such as payment providers, social media services, or apps or websites that use Allez’s APIs or whose APIs Allez uses.</li>
      <li>Allez business partners in connection with the Allez Visa Card to the extent disclosed in the terms and conditions for the card.</li>
      <li>Vendors who help us verify users’ identity, background information, and eligibility to work, for regulatory, safety, and security purposes.</li>
      <li>Insurance, vehicle, or financial services providers for drivers and/or delivery persons.</li>
      <li>Partner transportation companies (for drivers or delivery persons who use our services through an account associated with such a company).</li>
      <li>Publicly available sources.</li>
      <li>Marketing service providers.</li>
      </ul>
      <p>Allez may combine the data collected from these sources with other data in its possession.</p>

      <h3>B. How we use personal data</h3>
      <p>Allez collects and uses data to enable reliable and convenient transportation, delivery, and other products and services. We also use the data we collect:</p>
      <ul>
      <li>To enhance the safety and security of our users and services</li>
      <li>For customer support</li>
      <li>For research and development</li>
      <li>To enable communications between users</li>
      <li>To send marketing and non-marketing communications to users</li>
      <li>In connection with legal proceedings</li>
      </ul>
      <p>Allez does not sell or share user personal data with third parties for their direct marketing, except with users’ consent.</p>
      <p>Allez uses the data it collects for purposes including:</p>
      <p>1- Providing services and features. Allez uses the data we collect to provide, personalize, maintain, and improve our products and services.</p>
      <p>This includes using the data to:</p>
      <ul>
      <li>Create and update users’ accounts.</li>
      <li>Verify drivers’ and delivery persons’ identity, background history, and eligibility to work.</li>
      <li>Enable transportation, deliveries, and other services.</li>
      <li>Offer, process, or facilitate payments for our services.</li>
      <li>Offer, obtain, provide, or facilitate insurance, vehicle, invoicing, or financing solutions in connection with our services.</li>
      <li>Track and share the progress of rides or deliveries.</li>
      <li>Enable features that allow users to share information with other people, such as when riders submit a compliment about a driver, when delivery recipients provide feedback for a restaurant or delivery person, refer a friend to Allez, split fares, or share ETA and location with their contacts.</li>
      <li>Enable features to personalize users’ Allez accounts, such as creating bookmarks for favorite places, and to enable quick access to previous destinations. We may, for example, present an Allez Eats user with personalized restaurant or food recommendations based on their prior orders. Please see the section of this notice titled “Choice and transparency” to learn how to object to this use of personal data.</li>
      <li>Enable Accessibility features that make it easier for users with disabilities to use our services, such as those that enable deaf or hard-of-hearing drivers to alert their riders of their disabilities, allow only text messages from riders, and enable receipt of flashing trip request notifications instead of sound notifications on their mobile device.</li>
      <li>Perform internal operations necessary to provide our services, including to troubleshoot software bugs and operational problems; to conduct data analysis, testing, and research; and to monitor and analyze usage and activity trends.</li>
      </ul>

      <p>2- Safety and security. We use personal data to help maintain the safety, security, and integrity of our services and users. This includes:</p>
      <ul>
      <li>Screening riders, drivers, and delivery persons before enabling their use of our services and at subsequent intervals, including through reviews of background checks, where permitted by law, to help prevent use of our services by unsafe drivers and/or riders.</li>
      <li>Using data from drivers’ or delivery persons’ devices to help identify unsafe driving behavior such as speeding or harsh braking and acceleration, and to inform them of safer driving practices. We also use data from delivery persons’ devices to verify the type of vehicles they used to provide deliveries.</li>
      <li>In certain regions, using information derived from driver’s license photos, and other photos submitted to Allez, for safety and security purposes. This includes Allez’s Real-Time ID Check feature, which prompts drivers and delivery persons to share a selfie before going online to help ensure that the driver or delivery persons using the app matches the Allez account we have on file. This also includes comparing photographs that we have on file against photographs (i) of other users to prevent identity-borrowing, and (ii) from public databases to verify user identity.</li>
      <li>Using device, location, profile, usage, and other data to prevent, detect, and combat fraud or unsafe activities.</li>
      <li>Using user ratings and feedback to encourage compliance with our Community Guidelines and as grounds for deactivating drivers and delivery persons with low ratings or who otherwise violated such guidelines in certain countries.</li>
      </ul>

      <p>3- Customer support. Allez uses the information we collect (including recordings of customer support calls with notice to and the consent of the user) to provide customer support, including to:</p>
      <ul>
      <li>Direct questions to the appropriate customer support person</li>
      <li>Investigate and address user concerns</li>
      <li>Monitor and improve our customer support responses and processes</li>
      </ul>
      <p>4- Research and development. We may use the data we collect for testing, research, analysis, product development, and machine learning to improve the user experience. This helps us to improve and enhance the safety and security of our services, improve our ability to prevent the use of our services for illegal or improper purposes, develop new features and products, and facilitate insurance and finance solutions in connection with our services.</p>
      <p>5- Enabling communications between users. For example, a driver may message or call a rider to confirm a pickup location, a rider may contact a driver to retrieve a lost item, or a restaurant or delivery persons may call a delivery recipient with information about their order.</p>
      <p>6- Marketing. Allez may use the data we collect to market our services to our users. This includes sending users communications about Allez services, features, promotions, sweepstakes, studies, surveys, news, updates, and events.</p>
      <p>We may also send communications to our users about products and services offered by Allez partners. For example, if a user has placed an Allez Eats order, we may provide recommendations, promotions, or ads about similar food offered by other Allez partners. Although we may send users communications about Allez partners’ products and services, we do not sell users’ personal data to, or share it with, such partners or others for purposes of their own direct marketing or advertising, except with users’ consent.</p>
      <p>We may use the data we collect to personalize the marketing communications (including advertisements) that we send, including based on user location, past use of Allez’s services, and user preferences and settings.</p>
      <p>We may also send users communications regarding elections, ballots, referenda, and other political and notice processes that relate to our services. For example, Allez has notified some users by email of ballot measures or pending legislation relating to the availability of Allez’s services in those users’ areas.</p>
      <p>7- Non-marketing communications. Allez may use the data we collect to generate and provide users with receipts; inform them of changes to our terms, services, or policies; or send other communications that aren’t for the purpose of marketing the services or products of Allez or its partners.</p>
      <p>8- Legal proceedings and requirements. We may use the personal data we collect to investigate or address claims or disputes relating to use of Allez’s services, or as otherwise allowed by applicable law, or as requested by regulators, government entities, and official inquiries.</p>
      <p>9- Automated decision-making</p>
      <p>We use personal data to make automated decisions relating to use of our services. This includes:</p>
      <ul>
      <li>Enabling dynamic pricing, in which the price of a ride, or the delivery fee for Allez Eats orders, is determined based on constantly varying factors such as the estimated time and distance, the predicted route, estimated traffic, and the number of riders and drivers using Allez at a given moment.</li>
      <li>Matching available drivers and delivery persons to users requesting services. Users can be matched based on availability, proximity, and other factors. Please see here for further information about our matching process.</li>
      <li>Determining driver and delivery person ratings, and deactivating drivers, riders, and delivery persons with low ratings. For more information about how ratings are determined and used, please see here for rider ratings, here for driver ratings, and here for delivery person ratings. Please also see the section below titled “Ratings look-up” for further information.</li>
      <li>Deactivating users who are identified as having engaged in fraud or activities that may otherwise harm Allez, its users, and others. In some cases, such as when a user is determined to be abusing Allez’s referral program, such behavior may result in automatic deactivation.</li>
      <li>Using driver location information, and communications between riders and drivers, to identify cancellation fees earned or induced through fraud. For example, if we determine by using such information that a driver is delaying a rider pickup in order to induce a cancellation, we will not charge the rider a cancellation fee and will adjust the amounts paid to the driver to omit such a fee. To object to such adjustment, please contact Allez customer support.</li>
      </ul>
      <p>Click the links in this section for more information about these processes. To object to a deactivation resulting from these processes, please contact Allez customer support.</p>

      <h3>C. Cookies and third-party technologies</h3>
      <p>Allez and its partners use cookies and other identification technologies on our apps, websites, emails, and online ads for purposes described in this notice.</p>
      <p>Cookies are small text files that are stored on browsers or devices by websites, apps, online media, and advertisements. Allez uses cookies and similar technologies for purposes such as:</p>
      <ul>
      <li>Authenticating users</li>
      <li>Remembering user preferences and settings</li>
      <li>Determining the popularity of content</li>
      <li>Delivering and measuring the effectiveness of advertising campaigns</li>
      <li>Analyzing site traffic and trends, and generally understanding the online behaviors and interests of people who interact with our services</li>
      </ul>
      <p>We may also allow others to provide audience measurement and analytics services for us, to serve advertisements on our behalf across the Internet, and to track and report on the performance of those advertisements. These entities may use cookies, web beacons, SDKs, and other technologies to identify the devices used by visitors to our websites, as well as when they visit other online sites and services.</p>
      <p>Please see our Cookie Notice for more information regarding the use of cookies and other technologies described in this section.</p>

      <h3>D. Data sharing and disclosure</h3>
      <p>Some of Allez’s products, services, and features require that we share data with other users or at a user’s request. We may also share data with our affiliates, subsidiaries, and partners, for legal reasons or in connection with claims or disputes.</p>
      <p>Allez may share the data we collect:</p>
      <h5>1- With other users</h5>
      <p>This includes sharing:</p>
      <ul>
      <li>Riders’ first name, rating, and pickup and/or dropoff locations with drivers.</li>
      <li>Riders’ first name with other riders in a carpool trip. Riders in carpool trips may also see the dropoff location of the other riders.</li>
      <li>Delivery recipients’ first name, delivery address, and order information with their delivery person and restaurant. We may also share ratings and feedback, or other information to the extent required by law, with the restaurant partner and delivery person.</li>
      <li>For drivers and delivery persons, we may share data with the rider(s), delivery recipient(s) and restaurant partner(s), including name and photo; vehicle make, model, color, license plate, and vehicle photo; location (before and during trip); average rating provided by users; total number of trips; length of use of the Allez app; contact information (depending upon applicable laws); and driver or delivery person profile, including compliments and other feedback submitted by past users.</li>
      <li>We also provide riders and delivery recipients with receipts containing information such as a breakdown of amounts charged, driver or delivery person first name, photo, route map, and such other information required on invoices in the country or region where the driver or delivery person operates.</li>
      <li>For those who participate in Allez’s referral program, we share certain personal data of referred users, such as trip count, with the user who referred them, to the extent relevant to determining the referral bonus.</li>
      </ul>
      <h5>2- At the user’s request</h5>
      <p>This includes sharing data with:</p>
      <ul>
      <li>Other people at the user’s request. For example, we share a user’s ETA and location with a friend when requested by that user, or a user’s trip information when they split a fare with a friend.</li>
      <li>Allez business partners. For example, if a user requests a service through a partnership or promotional offering made by a third party, Allez may share certain data with those third parties. This may include, for example, other services, platforms, apps, or websites that integrate with our APIs; vehicle suppliers or services; those with an API or service with which we integrate; or restaurant partners or other Allez business partners and their users in connection with promotions, contests, or specialized services.</li>
      </ul>

      <h5>3- With the general public</h5>
      <p>Questions or comments from users submitted through public forums such as Allez blogs and Allez social media pages may be viewable by the public, including any personal data included in the questions or comments submitted by a user.</p>

      <h5>4- With the Allez account owner</h5>
      <p>If a user requests transportation or places an order using an account owned by another party, we may share their order or trip information, including real-time location data, with the owner of that account. This occurs, for example, when:</p>
      <ul>
      <li>A rider uses their employer’s Allez for Business profile, such as when they take trips arranged through Allez Central</li>
      <li>A driver or delivery person uses an account owned by or associated with an Allez partner transportation company or restaurant</li>
      <li>A rider takes a trip arranged by a friend or under a Family Profile</li>
      <li>A delivery person acts as a substitute (UK only)</li>
      </ul>

      <h5>5- With Allez subsidiaries and affiliates</h5>
      <p>We share data with our subsidiaries and affiliates to help us provide our services or conduct data processing on our behalf. For example, Allez processes and stores data in the Saint Lucia on behalf of its international subsidiaries and affiliates.</p>
      <h5>6- With Allez service providers and business partners</h5>
      <p>Allez provides data to vendors, consultants, marketing partners, research firms, and other service providers or business partners. These include:</p>
      <ul>
      <li>Payment processors and facilitators</li>
      <li>Background check and identity verification providers (drivers, delivery persons and cash riders only)</li>
      <li>Cloud storage providers</li>
      <li>Google, in connection with the use of Google Maps in Allez’s apps (see Google’s privacy policy for information on their collection and use of data)</li>
      <li>Facebook, in connection with the use of the Facebook Business Tools in Allez’s apps and websites (see Facebook’s privacy policy for information on their collection and use of data)</li>
      <li>Marketing partners and marketing platform providers, including social media advertising services</li>
      <li>Data analytics providers</li>
      <li>Research partners, including those performing surveys or research projects in partnership with Allez or on Allez’s behalf</li>
      <li>Vendors that assist Allez to enhance the safety and security of its apps</li>
      <li>Consultants, lawyers, accountants, and other professional service providers</li>
      <li>Fleet partners</li>
      <li>Insurance and financing partners</li>
      <li>Airports</li>
      <li>Lime and other local providers</li>
      <li>Restaurant partners and/or their point of sale providers</li>
      <li>Vehicle solution vendors or third-party vehicle suppliers</li>
      </ul>

      <h5>7- For legal reasons or in the event of a dispute</h5>
      <p>Allez may share users’ personal data if we believe it’s required by applicable law, regulation, operating license or agreement, legal process or governmental request, or where the disclosure is otherwise appropriate due to safety or similar concerns.</p>
      <p>This includes sharing personal data with law enforcement officials, public health officials, other government authorities, airports (if required by the airport authorities as a condition of operating on airport property), or other third parties as necessary to enforce our Terms of Service, user agreements, or other policies; to protect Allez’s rights or property or the rights, safety, or property of others; or in the event of a claim or dispute relating to the use of our services. If you use another person’s credit card, we may be required by law to share your personal data, including trip or order information, with the owner of that credit card.</p>
      <p>Please see Allez’s Guidelines for Law Enforcement Authorities for more information.</p>
      <p>This also includes sharing personal data with others in connection with, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or into another company.</p>

      <h5>8- With consent</h5>
      <p>Allez may share a user’s personal data other than as described in this notice if we notify the user and they consent to the sharing.</p>

      <h3>E. Data retention and deletion</h3>
      <p>Allez retains user data for as long as necessary for the purposes described above.</p>
      <p>Users may request deletion of their accounts at any time. Allez may retain user data after a deletion request due to legal or regulatory requirements or for the reasons stated in this policy.</p>
      <p>Allez retains user data for as long as necessary for the purposes described above. This means that we retain different categories of data for different periods of time depending on the category of user to whom the data relates, the type of data, and the purposes for which we collected the data.</p>
      <p>Users may request deletion of their account at any time through the Settings > Privacy menus in the Allez app, or through Allez’s website (riders and delivery recipients here; drivers and delivery persons here).</p>
      <p>Following an account deletion request, Allez deletes the user’s account and data, unless they must be retained due to legal or regulatory requirements, for purposes of safety, security, and fraud prevention, or because of an issue relating to the user’s account such as an outstanding credit or an unresolved claim or dispute. Because we are subject to legal and regulatory requirements relating to drivers and delivery persons, this generally means that we retain their account and data for a minimum of 7 years after a deletion request. For riders and delivery recipients, their data is generally deleted within 90 days of a deletion request, except where retention is necessary for the above reasons.</p>

      <h3>F. Grounds for processing</h3>
      <p>We only collect and use personal data where we have lawful grounds to do so. These include processing user personal data to provide requested services and features, for purposes of Allez’s legitimate interests or those of other parties, to fulfill our legal obligations, or based on consent.</p>
      <p>We collect and use personal data only where we have one or more lawful grounds for doing so. Such grounds may vary depending on where our users are located, but generally include processing personal data:</p>
      <h5>A- To provide requested services and features</h5>
      <p>In order to provide our services, we must collect and use certain personal data. This includes:</p>
      <ul>
      <li>User profile data, which we use to establish and maintain user accounts; verify user identity; communicate with users about their trips, orders, and accounts; and enable users to make payments or receive earnings</li>
      <li>Background trip information, which is used to verify an applicant’s eligibility to be a driver or delivery person</li>
      <li>Driver and delivery person location data, which we use to track trips and assist with navigation</li>
      <li>Usage data, which is necessary to maintain, optimize, and enhance Allez’s services, including to determine incentives, connect riders and drivers, and calculate costs of trips and driver earnings</li>
      <li>Transaction information</li>
      <li>Information relating to customer support</li>
      </ul>

      <h5>B- For purposes of the legitimate interests of Allez or other parties</h5>
      <p>This includes using personal data to maintain and enhance our users’ safety and security. For example, we use personal data to prevent use of our services by users who have engaged in inappropriate or dangerous behavior, such as by retaining data of banned users to prevent their use of Allez’s apps. We also use usage data to prevent matching of riders and drivers for whom there is higher risk of conflict (for instance, because they have been the subject of prior complaints from other users).</p>
      <p>This also includes purposes such as combating fraud; improving our services, direct marketing, research, and development; and enforcing Allez’s Terms of Service.</p>
      <p>In addition, it includes using personal data to the extent necessary for the interests of other people or the general public, such as in connection with legal or insurance claims, and to protect the rights and safety of others.</p>

      <h5>C- To fulfill Allez’s legal obligations</h5>
      <p>For example, Allez is subject to laws and regulations in many cities and countries that require it to collect and retain data about our users’ trips and deliveries, and to provide copies of such data to the government or other authorities. We collect and use personal data to comply with such laws.</p>
      <p>Allez may also share data with law enforcement regarding criminal acts or threats to public safety, or requests by third parties pursuant to legal processes. Allez may also share information with public health authorities where required or permitted by law. For more information about such sharing, please see Allez’s Guidelines for Law Enforcement Authorities - Saint Lucia, Guidelines for Law Enforcement Authorities - Outside Saint Lucia, and Guidelines for Third Party Data Requests and Service of Legal Documents.</p>

      <h5>D- With consent</h5>
      <p>Allez may collect and use personal data based on the user’s consent. For example, we may collect personal data through voluntary surveys. Responses to such surveys are collected on the basis of consent and will be deleted once no longer necessary for the purposes collected.</p>
      <p>A user who has provided consent to a collection or use of their personal data can revoke it at any time. However, the user will not be able to use any service or feature that requires collection or use of that personal data.</p>

      <h3>IV. Choice and transparency</h3>
      <p>Allez enables users to access and control the data that Allez collects, including through:</p>
      <ul>
      <li>In-app settings</li>
      <li>Device permissions</li>
      <li>In-app ratings pages</li>
      <li>Marketing opt-outs</li>
      </ul>
      <p>Allez also enables users to request access to or copies of their data, changes or updates to their accounts, deletion of their accounts, or that Allez restrict its processing of user personal data.</p>

      <h5>A- Privacy settings</h5>
      <p>Settings menus in the Allez app for riders give them the ability to set or update their location-sharing preferences and their preferences for receiving mobile notifications from Allez. Information about these settings, how to set or change these settings, and the effect of turning off these settings is described below.</p>
      <ul>
      <li>Location data</li>
      </ul>
      <p>Allez uses riders’ device location services to make it easier to get a ride whenever they need one. Location data helps improve our services, including pickups, navigation, and customer support.</p>
      <p>Riders may enable or disallow Allez to collect location data from their mobile devices through the Settings > Privacy menus in the Allez app. Riders and delivery recipients can enable or disallow such collections through the settings on their mobile device.</p>
      <ul>
      <li>Share Live Location (riders)</li>
      </ul>

      <p>Riders who have enabled Allez to collect location data from their mobile device may also enable Allez to share their location with their driver from the time the ride is requested to the start of the trip. This can help improve pickups for both drivers and riders, particularly in crowded areas.</p>
      <p>Riders may enable or disable this feature through the Settings > Privacy menus in the Allez app.</p>
      <ul><li>Emergency Data Sharing (riders)</li></ul>
      <p>Riders who have enabled Allez to collect location data from their mobile device may also enable the Emergency Data Sharing feature that shares data with emergency police, fire, and ambulance services. Such data includes approximate location at the time the emergency call was placed; the car’s make, model, color, and license plate information; the rider’s name and phone number; pickup and dropoff locations; and the driver’s name.</p>
      <p>Riders may enable or disable this feature through the Settings > Privacy menus or the Safety Center in the Allez app.</p>
      <ul><li>Notifications: account and trip updates</li></ul>
      <p>Allez provides users with trip status notifications and updates related to activity on their account. These notifications are a necessary part of using the Allez app and cannot be disabled. However, users may choose the method by which they receive these notifications through the Settings > Privacy menus in the Allez app.</p>
      <ul><li>Notifications: discounts and news</li></ul>
      <p>Users may enable Allez to send push notifications about discounts and news from Allez. Push notifications may be enabled or disabled through the Settings > Privacy menus in the Allez app.</p>
      <ul><li>Communications from restaurant partners</li></ul>
      <p>Delivery recipients who have opted in to communications from restaurant partners may stop sharing their data with each restaurant partner in their Allez Eats account settings.</p>

      <h5>B- Device permissions</h5>
      <p>Most mobile device platforms (iOS, Android, etc.) have defined certain types of device data that apps cannot access without the device owner’s permission, and these platforms have different methods for how that permission can be obtained. iOS devices notify users the first time the Allez app requests permission to access certain types of data and gives users the option to grant or refuse permission. Android devices notify users of the permissions that the Allez app seeks before their first use of the app, and use of the app constitutes a grant of such permission.</p>

      <h5>C- Ratings look-up</h5>
      <p>After every trip, drivers and riders are able to rate each other on a scale from 1 to 5. An average of those ratings is associated with a user’s account and is displayed to other users for whom they provide or receive services. For example, rider ratings are available to drivers from whom they request transportation, and driver ratings are available to their riders.</p>
      <p>This 2-way system holds everyone accountable for their behavior. Accountability helps create a respectful, safe environment for drivers and riders.</p>
      <p>Riders can see their average rating in the main menu of the Allez app.</p>
      <p>Drivers can see their average rating after tapping their profile photo in the Allez Driver app.</p>
      <p>Delivery persons are also rated by delivery recipients and restaurant partners. Click here for more information.</p>

      <h5>D- Marketing opt-outs</h5>
      <p>Users may opt out of receiving promotional emails from Allez here. Users may also opt out of receiving emails and other messages from Allez by following the unsubscribe instructions in those messages. We may still send users who have opted out non-promotional communications, such as receipts for rides or information about their account.</p>

      <h5>E- User data requests</h5>
      <p>Allez provides users with a variety of ways to learn about, control, and submit questions and comments about Allez’s handling of their data. To make a request, please go here.</p>
      <ul>
      <li>Accessing data: Users can ask for an explanation of the data we collect from them and how we use it.</li>
      <li>Receiving data: Users can ask for a copy of data that Allez collects from them with their consent or as necessary to provide our services.</li>
      <li>Changing or updating data: Users can edit the name, phone number, email address, payment method, and photo associated with their account through the Settings menu in Allez’s apps or driver portal. They may also ask that Allez change or update their data, including if they believe such data is inaccurate or incomplete.</li>
      <li>Deleting data: Users may request deletion of their account at any time through the Settings > Privacy menus in the Allez app, or through Allez’s website (riders and delivery recipients here; drivers and delivery persons here).</li>
      <li>Objections, restrictions, and complaints: Users may request that we stop using all or some of their personal data, or that we limit our use of their data. Allez may continue to process data after such objection or request to the extent required or permitted by law.</li>
      </ul>
      <p>In addition, depending on their location, users may have the right to file a complaint relating to Allez’s handling of their personal data with the data protection authority in their country. For example, users in the European Union and South America may submit such requests to the data protection authorities in the country in which they live.</p>

      <h3>V. Updates to this notice</h3>
      <p>We may occasionally update this notice. Use of our services after an update constitutes consent to the updated notice to the extent permitted by law.</p>
      <p>We may occasionally update this notice. If we make significant changes, we will notify users in advance of the changes through the Allez apps or through other means, such as email. We encourage users to periodically review this notice for the latest information on our privacy practices.</p>
      <p>After such notice, use of our services by users in countries outside the European Union will be understood as consent to the updates to the extent permitted by law.</p>

      </>);
}
