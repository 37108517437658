import axios from "axios";

export const LOGIN_URL = "login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "forgot-password";
export const UPDATE_PROFILE = "updateProfile";
export const UPDATE_PASSWORD = "changePassword";

export const ME_URL = "me";
export const VERIFY_TOKEN_URL = "acceptInvitation";

export function login(email, password) {
  var paramsArray = JSON.stringify( { email, password });
  return axios.post(LOGIN_URL, {paramsArray});
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
    var paramsArray = JSON.stringify( { email });
  return axios.post(REQUEST_PASSWORD_URL, { paramsArray });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function updateProfile(user, img) {
  var paramsArray = JSON.stringify( user);
  const data = new FormData();
  data.append('paramsArray', paramsArray);
  data.append('img', img);
  console.log("updateProfile: ", paramsArray);
  return axios.post(UPDATE_PROFILE, data);
}

export function updatePassword(user) {
  var paramsArray = JSON.stringify( user);
  return axios.post(UPDATE_PASSWORD, {paramsArray});
}

// Accept Invitation => verify the admin from the server
export function verifyAdmin(token) {
  return axios.get(`${VERIFY_TOKEN_URL}/${token}`);
}
