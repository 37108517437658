import React from "react";
import { useSubheader } from "../../_metronic/layout";
import ButtonGroupExamplesPage from "../modules/ReactBootstrapExamples/ButtonGroupExamplesPage";
import { Grid, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

        width: '100%',
        flexDirection: 'column'
    },
    button: {
        width: '20vh',
        height: '3.5vh',
        marginRight: '104vh',
        fontWeight: '500',

    },
    container: { display: 'flex', marginTop: '4vh' },
    item: {
        marginRight: '5vh'
    }
}));
export const FeedBackPage = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("My Custom title");
    const classes = useStyles();

    return (<div className={classes.root}>
        <h1>Feedback</h1>
        <div className={classes.container}>

            <Grid container spacing={4} >
                <Grid item xs={2} className={classes.item}>
                    <Button class="btn btn-primary btn-elevate" variant="contained" color="secondary" onClick={() => window.open('https://forms.clickup.com/8402633/f/80dp9-13584/37B79GS33IXYI8TGLM')}>
                        Add New Feature
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <Button class="btn btn-primary btn-elevate" variant="contained" color="secondary" onClick={() => window.open('https://forms.clickup.com/8402633/f/80dp9-13564/RGJYF2W4WA76FOZZBF')}>
                        Report An Issue
                    </Button>
                </Grid>
            </Grid>

        </div>
    </div>);
};
