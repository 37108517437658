/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import {checkIsActive} from "../../../../_helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  const handleReset = () => {
    localStorage.setItem('Number', 0);
    localStorage.removeItem("searchedName");
    localStorage.removeItem("Direction");
    localStorage.removeItem("ChangedColumn");
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/dashboard">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="tachometer-alt" />

              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/> */}
            </span>
              <span className="menu-text"><h4 className="my-auto">Dashboard</h4></span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/admin/configuration", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/configuration">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="chart-bar" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Configuration</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/ManageCarMakes", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/ManageCarMakes">
            <span className="svg-icon menu-icon">
            <i className="fas fa-toolbox" style={{fontSize: 22, color: "#6A6D6C"}}></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Manage Car Makes</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admins", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admins">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="user-alt" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Admin</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/drivers", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/drivers">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="dharmachakra" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Driver</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/DriverBankDetails", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/DriverBankDetails">
            <span className="svg-icon menu-icon">
            <i className="fa fa-university" aria-hidden="true" style={{fontSize: 22, color:"#6A6D6C"}}></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Driver Bank Details</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/vehicles", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/vehicles">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="taxi" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Driver Vehicles</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/vehicletypes", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/vehicletypes">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="car" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Vehicle Type</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/riders", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/riders">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="user-friends" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Riders</h4></span>
            </NavLink>
          </li>
          {/*<li
              className={`menu-item ${getMenuItemActive("/my-page", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/my-page">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="keyboard" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Manual Booking</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/my-page", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/my-page">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="clock" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Ride Later Booking</h4></span>
            </NavLink>
          </li>*/}
          <li
              className={`menu-item ${getMenuItemActive("/admin/trips", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/trips">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="compass" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Trips</h4></span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/admin/trip-logs", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/trip-logs">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="clock" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Trip Logs</h4></span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/admin/promocode", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/promocode">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="ticket-alt" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Promo Code</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/overall-view", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/overall-view">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="city" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Overall View</h4></span>
            </NavLink>
          </li>
          {/*<li
              className={`menu-item ${getMenuItemActive("/admin/heatmap-view", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/heatmap-view">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="map" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Heat Map View</h4></span>
            </NavLink>
          </li>*/}
          <li
              className={`menu-item ${getMenuItemActive("/admin/reviews", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/reviews">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="comment-alt" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Reviews</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/push-notifications", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/admin/push-notifications">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="file-alt" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Push Notifications</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/my-page", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/my-page">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="file-alt" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Reports</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/my-page", false)}`}
              aria-haspopup="true"
          >
            <NavLink onClick={handleReset} className="menu-link py-4" to="/feedback-page">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="file-alt" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Feedback</h4></span>
            </NavLink>
          </li>
          {/*<li
              className={`menu-item ${getMenuItemActive("/my-page", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/my-page">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="map-marker-alt" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Manage Locations</h4></span>
            </NavLink>
          </li>*/}
          {/*<li
              className={`menu-item ${getMenuItemActive("/admin/decline-trips", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/decline-trips">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="times-circle" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Decline Trips</h4></span>
            </NavLink>
          </li>*/}
          {/*end::1 Level*/}

          {/* Components */}
          {/* begin::section */}

                {/*end::2 Level*/}

          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
