/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {} from "react";

export function MixedWidget15Driver({ className }) {
  

  return (
    <div className={`card bg-light border-0 shadow-none my-auto card-custom ${className}`}>
     
      <div className="card-body d-flex flex-column">
        <div className="row py-4">
          <div className="col-md-8">
            <h1>Drivers Count</h1>

          </div>
          <div className="col-md-4 text-right mr-auto">
            <h1>180</h1>

          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <h4>TODAY</h4>

          </div>
          <div className="col-md-4 text-right mr-auto">
            <h4>10</h4>

          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <h4>THIS MONTH</h4>

          </div>
          <div className="col-md-4 text-right mr-auto">
            <h4>50</h4>

          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <h4>THIS YEAR</h4>

          </div>
          <div className="col-md-4 text-right mr-auto">
            <h4>120</h4>

          </div>
        </div>
        
        
      </div>
    </div>
  );
}




