import React from "react";
import { Route } from "react-router-dom";
import { DriversLoadingDialog } from "./drivers-loading-dialog/DriversLoadingDialog";
import { DriverEditDialog } from "./driver-edit-dialog/DriverEditDialog";
import { DriverEdit } from "./driver-edit/DriverEdit";
import { DriverDeleteDialog } from "./driver-delete-dialog/DriverDeleteDialog";
import { DriversDeleteDialog } from "./drivers-delete-dialog/DriversDeleteDialog";
import { DriversFetchDialog } from "./drivers-fetch-dialog/DriversFetchDialog";
import { DriversUpdateStateDialog } from "./drivers-update-status-dialog/DriversUpdateStateDialog";
import { DriversUIProvider } from "./DriversUIContext";
import { DriversCard } from "./DriversCard";

export function DriversPage({ history }) {
  const driversUIEvents = {
    newDriverButtonClick: () => {
      history.push("/drivers/new");
    },
    openEditDriverDialog: (id) => {
      history.push(`/drivers/${id}/edit`);
    },
    openDeleteDriverDialog: (id) => {
      history.push(`/drivers/${id}/delete`);
    },
    openDeleteDriversDialog: () => {
      history.push(`/drivers/deleteDrivers`);
    },
    openFetchDriversDialog: () => {
      history.push(`/drivers/fetch`);
    },
    openUpdateDriversStatusDialog: () => {
      history.push("/drivers/updateStatus");
    }
  }

  return (
    <DriversUIProvider driversUIEvents={driversUIEvents}>
      <DriversLoadingDialog />
      <Route path="/drivers/new">
        {({ history, match }) => (
          <DriverEditDialog
            show={match != null}
            onHide={() => {
              history.push("/drivers");
            }}
          />
        )}
      </Route>
      <Route path="/drivers/:id/edit">
        {({ history, match }) => (
          <DriverEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/drivers");
            }}
          />
        )}
      </Route>
      <Route path="/drivers/deleteDrivers">
        {({ history, match }) => (
          <DriversDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/drivers");
            }}
          />
        )}
      </Route>
      <Route path="/drivers/:id/delete">
        {({ history, match }) => (
          <DriverDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/drivers");
            }}
          />
        )}
      </Route>
      <Route path="/drivers/fetch">
        {({ history, match }) => (
          <DriversFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/drivers");
            }}
          />
        )}
      </Route>
      <Route path="/drivers/updateStatus">
        {({ history, match }) => (
          <DriversUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/drivers");
            }}
          />
        )}
      </Route>
      <DriversCard />
    </DriversUIProvider>
  );
}
