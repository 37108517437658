import React, { useEffect, useMemo } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/adminsActions";
import MUIDataTable from 'mui-datatables';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  toAbsoluteUrl
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../AdminsUIHelpers";
import { useAdminsUIContext } from "../AdminsUIContext";

export function AdminsTable() {
  // Admins UI Context
  const adminsUIContext = useAdminsUIContext();
  const adminsUIProps = useMemo(() => {
    return {
      ids: adminsUIContext.ids,
      setIds: adminsUIContext.setIds,
      queryParams: adminsUIContext.queryParams,
      setQueryParams: adminsUIContext.setQueryParams,
      openEditAdminDialog: adminsUIContext.openEditAdminDialog,
      openDeleteAdminDialog: adminsUIContext.openDeleteAdminDialog,
    };
  }, [adminsUIContext]);

  const getMuiTheme = () => createTheme({
    overrides: {
      MUIDataTableToolbar: {
        root: {
          background: '#ffffff'
        },
        button:{
          display: 'none'
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          height: 'unset',
          maxHeight: 'none'
        }
      },
      MUIDataTableSearch:{
        clearIcon:{
          display: 'none'
        }
      },

    }
  })

  // Getting curret state of admins list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.admins }),
    shallowEqual
  );
  const { entities } = currentState;

  // Admins Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    adminsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchAdmins(adminsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminsUIProps.queryParams, dispatch]);

  const dt_columns = [
    {
      name: "id",
      options: {
          filter: false,
          sort: false,
          display: false
      }
    },
    {
      name: "fname",
      options: {
          filter: true,
          sort: true,
          display: true
      }
    },
    {
      name: "lname",
      options: {
          filter: true,
          sort: true,
          display: true
      }
    },
    {
      name: "email",
      options: {
          filter: true,
          sort: true,
          display: true
      }
    },
    {
      name: "gender",
      options: {
          filter: true,
          sort: true,
          display: true
      }
    },
    {
      name: "status",
      options: {
          filter: true,
          sort: true,
          display: true,
          customBodyRender: (value, tableMeta) => {
            return (
                <>
                    <span className="label label-lg label-light-{ uiHelpers.AdminStatusCssClasses[value] } label-inline">{uiHelpers.AdminStatusTitles[value]}</span>
                </>
            );
          }
      }
    },
    {
      name: "utype",
      options: {
          filter: true,
          sort: true,
          display: true,
          customBodyRender: (value, tableMeta) => {
            return (
                <>
                <span
                  className={`label label-dot label-${
                    uiHelpers.AdminTypeCssClasses[value]
                  } mr-2`}
                ></span>
                &nbsp;
                <span className={`font-bold font-${
                    uiHelpers.AdminTypeCssClasses[value]
                } label-inline`}>{uiHelpers.AdminTypeTitles[value]}</span>
                </>
            );
          }
      }
    },
    {
      name: "id",
      label: "Actions",
      options:{
          filter: false,
          sort: false,
          display: true,
          customBodyRender: (value, row) => {

            return (
                <>
                  <a
                    title="Edit admin"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={() => adminsUIProps.openEditAdminDialog(value)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                      />
                    </span>
                  </a>
                  <> </>

                  <a
                    title="Delete admin"
                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                    onClick={() => adminsUIProps.openDeleteAdminDialog(value)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                    </span>
                  </a>
                </>
            );
          }
      },
      setCellProps: (value) => {
        return {
          className: 'text-right pr-0'
        };
      },
    },
  ];

  const tbl_options = {
      filterType: 'dropdown',
      responsive: 'scroll',
      download: false,
      print: false,
      selectableRows: false,
      filter: true,
      viewColumns: true,
      searchPlaceholder: "Search"
    };

  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={entities === null ? [] : entities} columns={dt_columns} options={tbl_options} />
      </MuiThemeProvider>

    </>
  );
}
