import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { DriversTable } from "./drivers-table/DriversTable";
import { DriversGrouping } from "./drivers-grouping/DriversGrouping";
import { useDriversUIContext } from "./DriversUIContext";

export function DriversCard() {
  const driversUIContext = useDriversUIContext();
  const driversUIProps = useMemo(() => {
    return {
      ids: driversUIContext.ids,
      newDriverButtonClick: driversUIContext.newDriverButtonClick,
    };
  }, [driversUIContext]);

  return (
    <Card>
      <CardHeader title="Drivers list">
        <CardHeaderToolbar>
          {/*<button
            type="button"
            className="btn btn-primary"
            onClick={driversUIProps.newDriverButtonClick}
          >
            New Driver
          </button>*/}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {driversUIProps.ids.length > 0 && <DriversGrouping />}
        <DriversTable />
      </CardBody>
    </Card>
  );
}
