import axios from "axios";

export const CUSTOMERS_URL = "drivers";

// CREATE =>  POST: add a new driver to the server
export function createDriver(driver) {
    let paramsArray = JSON.stringify(driver);
  return axios.post(`${CUSTOMERS_URL}/create`, { paramsArray });
  //return axios.post(CUSTOMERS_URL, { driver });
}

// READ
export function getAllDrivers() {
    console.log('get drivers');
  return axios.get(CUSTOMERS_URL);
}

export function getDriverById(driverId) {
  return axios.get(`${CUSTOMERS_URL}/${driverId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDrivers(queryParams) {
    console.log('get drivers');
    let paramsArray = JSON.stringify(queryParams);
  return axios.post(`${CUSTOMERS_URL}`, { paramsArray });
}

// UPDATE => PUT: update the driver on the server
export function updateDriver(driver) {
    let paramsArray = JSON.stringify(driver);
  return axios.put(`${CUSTOMERS_URL}/${driver.id}`, { paramsArray });
}

// UPDATE Status
export function updateStatusForDrivers(ids, status) {
    let paramsArray = JSON.stringify({ids,status});
  return axios.post(`${CUSTOMERS_URL}/updateStatusForDrivers`, { paramsArray });
}

// DELETE => delete the driver from the server
export function deleteDriver(driverId) {
  return axios.delete(`${CUSTOMERS_URL}/${driverId}`);
}

// DELETE Drivers by ids
export function deleteDrivers(ids) {
    let paramsArray = JSON.stringify({ids});
  return axios.post(`${CUSTOMERS_URL}/deleteDrivers`, { paramsArray });
}
