import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {AdminsPage} from "./modules/Admins/pages/AdminsPage";
import {DriversPage} from "./modules/Drivers/pages/DriversPage";
import { DriverEdit } from "./modules/Drivers/pages/driver-edit/DriverEdit";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";
import { FeedBackPage } from "./pages/Feedback";


const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

const AdminPage = lazy(() =>
  import("./modules/Admin/pages/adminPage")
);

const SupportPage = lazy(() =>
  import("./modules/Support/pages/supportPage")
);

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    const {isSupport} = useSelector(
        ({auth}) => ({
            isSupport: (auth.user != null && auth.user.utype === 1),
        }),
        shallowEqual
    );

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    !isSupport? (
                        <Redirect exact from="/" to="/dashboard"/>
                    ):
                    (
                        <Redirect exact from="/" to="/support"/>
                    )
                }
                {
                    !isSupport ? (
                        <>
                        <ContentRoute path="/dashboard" component={DashboardPage}/>
                        <ContentRoute path="/builder" component={BuilderPage}/>
                        <ContentRoute path="/admins" component={AdminsPage}/>
                        <ContentRoute path="/drivers" component={DriversPage}/>
                        <ContentRoute path="/drivers/:id/edit" component={DriverEdit} />
                        <ContentRoute path="/my-page" component={MyPage}/>
                        <ContentRoute path="/feedback-page" component={FeedBackPage}/>
                        <Route path="/admin" component={AdminPage}/>
                        <Route path="/profile" component={UserProfilepage} />
                        <Route path="/user-profile" component={UserProfilepage} />
                        </>
                    ):
                    (
                        <>
                        <Route path="/support" component={SupportPage}/>
                        <Route path="/profile" component={UserProfilepage} />
                        <Route path="/user-profile" component={UserProfilepage} />
                        </>
                    )
                }


                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
