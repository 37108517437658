/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../_redux/driversActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { DriverEditForm } from "./DriverEditForm";
import { DriverVehicles } from "../driver-vehicles/DriverVehicles";
import { useSubheader } from "../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { DriverDocs } from "../driver-docs/DriverDocs";

const initDriver = {
  id: undefined,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  gender: "",
  wallet_balance: "",
  status: "",
  accept_cash: 0,
  paymentEmail: "",
  bankAccountHolderName: "",
  accountNumber: "",
  bankName: "",
  bankLocation: "",
  bankSwiftCode: "",
  profileDescription: "",
};

export function DriverEdit({
  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, driverForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.drivers.actionsLoading,
      driverForEdit: state.drivers.driverForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchDriver(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Driver";
    if (driverForEdit && id) {
      _title = `Edit driver '${driverForEdit.firstName} ${driverForEdit.lastName} - ${driverForEdit.email}'`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverForEdit, id]);

  const saveDriver = (values) => {
    if (!id) {
      dispatch(actions.createDriver(values)).then(() => backToDriversList());
    } else {
      dispatch(actions.updateDriver(values)).then(() => backToDriversList());
    }
  };

  const btnRef = useRef();
  const saveDriverClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToDriversList = () => {
    history.push(`/drivers`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToDriversList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {`  `}
          <button className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveDriverClick}
          >
            Save
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab("basic")}>
            <a
              className={`nav-link ${tab === "basic" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "basic").toString()}
            >
              Basic info
            </a>
          </li>
          {id && (
            <>
              {" "}
              <li className="nav-item" onClick={() => setTab("driverDocs")}>
                <a
                  className={`nav-link ${tab === "driverDocs" && "active"}`}
                  data-toggle="tab"
                  role="button"
                  aria-selected={(tab === "driverDocs").toString()}
                >
                  Driver Documents
                </a>
              </li>
              <li className="nav-item" onClick={() => setTab("driverVehicles")}>
                <a
                  className={`nav-link ${tab === "driverVehicles" && "active"}`}
                  data-toggle="tab"
                  role="tab"
                  aria-selected={(tab === "driverVehicles").toString()}
                >
                  Driver Vehicles
                </a>
              </li>
            </>
          )}
        </ul>
        <div className="mt-5">
          {tab === "basic" && (
            <DriverEditForm
              actionsLoading={actionsLoading}
              driver={driverForEdit || initDriver}
              btnRef={btnRef}
              saveDriver={saveDriver}
            />
          )}
          {tab === "driverDocs" && id && (
            <DriverDocs />
          )}
          {tab === "driverVehicles" && id && (
            <DriverVehicles />
          )}
        </div>
      </CardBody>
    </Card>
  );
}
