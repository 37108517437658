import React from "react";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function Topbar() {
  return (
    <div className="topbar">
      <div className="row">
        <Link to="/user-profile" className="font-weight-bold my-auto ">
            <FontAwesomeIcon icon="user-alt"  size="lg" color="black"/> <span className="px-2 text-dark">Profile</span>
        </Link>
        <Link to="/logout" className="font-weight-bold my-auto ">
              <FontAwesomeIcon icon="sign-out-alt"  size="lg" color="black"/> <span className="px-2 text-dark">Logout</span>
        </Link>
      </div>
    </div>
  );
}
