import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/adminsActions";
import { AdminEditDialogHeader } from "./AdminEditDialogHeader";
import { AdminEditForm } from "./AdminEditForm";
import { useAdminsUIContext } from "../AdminsUIContext";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
 let errorData;
export function AdminEditDialog({ id, show, onHide }) {
  const [showError, setShowError] = useState(false);
  // Admins UI Context
  const adminsUIContext = useAdminsUIContext();
  const adminsUIProps = useMemo(() => {
    return {
      initAdmin: adminsUIContext.initAdmin,
    };
  }, [adminsUIContext]);

  // Admins Redux state
  const dispatch = useDispatch();
  const { actionsLoading, adminForEdit, error } = useSelector(
    (state) => ({
      actionsLoading: state.admins.actionsLoading,
      adminForEdit: state.admins.adminForEdit,
      error: state.admins.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Admin by id
    dispatch(actions.fetchAdmin(id));
    if (error) {
      setShowError(true);
    } else {
      setShowError(true);
    }
  }, [id, dispatch]);

  // server request for saving admin
  const saveAdmin = (admin) => {
    if (!id) {
      // server request for creating admin
      dispatch(actions.createAdmin(admin)).then(() => onHide());
    } else {
      // server request for updating admin
      dispatch(actions.updateAdmin(admin)).then(() => onHide());
    }
  };
   const handleClose = () => {
    setShowError(false);
   }

  return (
    <div>
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <AdminEditDialogHeader id={id} />
      <AdminEditForm
        saveAdmin={saveAdmin}
        actionsLoading={actionsLoading}
        admin={adminForEdit || adminsUIProps.initAdmin}
        onHide={onHide}
        err= {error}
      />
    </Modal>
    {/* <Grid item xs={6}>
     <Snackbar open={showError} autoHideDuration={5000} >
       <Alert onClose={handleClose} severity={error ? "error" : null}>
      {error }  
       </Alert>
     </Snackbar>
   </Grid>  */}
    </div>
  );
}
