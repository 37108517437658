export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();
      if (window.location.href.indexOf("localhost") > -1)
        // config.baseURL = "http://allez.local/api/admin/";
        // config.baseURL = process.env.REACT_APP_BASE_URL || "/api/admin/";
        config.baseURL = "https://allezadmin.com/api/admin/";
      // config.baseURL = process.env.REACT_APP_BASE_URL || "/api/admin/";
      else config.baseURL = "/api/admin/";
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 403) {
        window.location = "/logout";
      }
      return error;
    }
  );
}
