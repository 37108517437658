import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./DriversUIHelpers";

const DriversUIContext = createContext();

export function useDriversUIContext() {
  return useContext(DriversUIContext);
}

export const DriversUIConsumer = DriversUIContext.Consumer;

export function DriversUIProvider({driversUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initDriver = {
    id: undefined,
    fname: "",
    lname: "",
    email: "",
    gender: "Male",
    status: 0,
    age: "",
    utype: 1
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initDriver,
    newDriverButtonClick: driversUIEvents.newDriverButtonClick,
    openEditDriverDialog: driversUIEvents.openEditDriverDialog,
    openDeleteDriverDialog: driversUIEvents.openDeleteDriverDialog,
    openDeleteDriversDialog: driversUIEvents.openDeleteDriversDialog,
    openFetchDriversDialog: driversUIEvents.openFetchDriversDialog,
    openUpdateDriversStatusDialog: driversUIEvents.openUpdateDriversStatusDialog
  };

  return <DriversUIContext.Provider value={value}>{children}</DriversUIContext.Provider>;
}
