import * as requestFromServer from "./driversCrud";
import {driversSlice, callTypes} from "./driversSlice";

const {actions} = driversSlice;

export const fetchDrivers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDrivers(queryParams)
    .then(response => {
      let entities = response.data.data;
      let totalCount = entities.length;
      //let entities = driverslist;
      dispatch(actions.driversFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find drivers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDriver = id => dispatch => {
  if (!id) {
    return dispatch(actions.driverFetched({ driverForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDriverById(id)
    .then(response => {
      const driver = response.data.data;
      dispatch(actions.driverFetched({ driverForEdit: driver }));
    })
    .catch(error => {
      error.clientMessage = "Can't find driver";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDriver = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDriver(id)
    .then(response => {
      dispatch(actions.driverDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete driver";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createDriver = driverForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createDriver(driverForCreation)
    .then(response => {
        let entities = response.data.data;
        let totalCount = entities.length;
        //let entities = driverslist;
        dispatch(actions.driversFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't create driver";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDriver = driver => dispatch => {
    console.log(driver);
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateDriver(driver)
    .then(() => {
      dispatch(actions.driverUpdated({ driver }));
    })
    .catch(error => {
      error.clientMessage = "Can't update driver";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDriversStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForDrivers(ids, status)
    .then(() => {
      dispatch(actions.driversStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update drivers status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDrivers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDrivers(ids)
    .then(() => {
      dispatch(actions.driversDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete drivers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
