import * as requestFromServer from "./adminsCrud";
import {adminsSlice, callTypes} from "./adminsSlice";

const {actions} = adminsSlice;

export const fetchAdmins = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findAdmins(queryParams)
    .then(response => {
      let entities = response.data.data;
      let totalCount = entities.length;
      //let entities = adminslist;
      dispatch(actions.adminsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find admins";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAdmin = id => dispatch => {
  if (!id) {
    return dispatch(actions.adminFetched({ adminForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAdminById(id)
    .then(response => {
      const admin = response.data.data;
      dispatch(actions.adminFetched({ adminForEdit: admin }));
    })
    .catch(error => {
      error.clientMessage = "Can't find admin";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteAdmin = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAdmin(id)
    .then(response => {
      dispatch(actions.adminDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete admin";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createAdmin = adminForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createAdmin(adminForCreation)
    .then(response => {
      if(response.data.success === 1) {
        let entities = response.data.data;
        let totalCount = entities.length;
        //let entities = adminslist;
        dispatch(actions.adminsFetched({ totalCount, entities }));
      } else {
         dispatch(actions.catchError({error: response.data.message , callType: callTypes.action }));
        }
    })
    .catch(error => {
      error.clientMessage = "Can't create admin";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateAdmin = admin => dispatch => {
    console.log(admin);
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAdmin(admin)
    .then(response => {
        let entities = response.data.data;
        let totalCount = entities.length;
        //let entities = adminslist;
        dispatch(actions.adminsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't update admin";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateAdminsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForAdmins(ids, status)
    .then(() => {
      dispatch(actions.adminsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update admins status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteAdmins = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAdmins(ids)
    .then(() => {
      dispatch(actions.adminsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete admins";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
