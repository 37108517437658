import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/driversActions";
import { DriverEditDialogHeader } from "./DriverEditDialogHeader";
import { DriverEditForm } from "./DriverEditForm";
import { useDriversUIContext } from "../DriversUIContext";

export function DriverEditDialog({ id, show, onHide }) {
  // Drivers UI Context
  const driversUIContext = useDriversUIContext();
  const driversUIProps = useMemo(() => {
    return {
      initDriver: driversUIContext.initDriver,
    };
  }, [driversUIContext]);

  // Drivers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, driverForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.drivers.actionsLoading,
      driverForEdit: state.drivers.driverForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Driver by id
    dispatch(actions.fetchDriver(id));
  }, [id, dispatch]);

  // server request for saving driver
  const saveDriver = (driver) => {
    if (!id) {
      // server request for creating driver
      dispatch(actions.createDriver(driver)).then(() => onHide());
    } else {
      // server request for updating driver
      dispatch(actions.updateDriver(driver)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <DriverEditDialogHeader id={id} />
      <DriverEditForm
        saveDriver={saveDriver}
        actionsLoading={actionsLoading}
        driver={driverForEdit || driversUIProps.initDriver}
        onHide={onHide}
      />
    </Modal>
  );
}
