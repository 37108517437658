import React from "react";
import {
    MixedWidget1,
    MixedWidget2,
    MixedWidget3,
    MixedWidget4,
    MixedWidget1Ride,
    MixedWidget2Ride,
    MixedWidget3Ride,
    MixedWidget4Ride,
    MixedWidget14,
    MixedWidget14Driver,
    MixedWidget15,
    MixedWidget15Driver
} from "../widgets";
export function Demo1Dashboard() {
    return (<>
            <div className="row">
                
                <div className="col-md-5 col-xxl-6">
                    <div className="col-md-12 py-4">
                        <h3>Site Statistics</h3>
                    </div>
                    <div className="row bg-light mx-1">
                        <div className="col-md-6 col-xxl-6">
                            <MixedWidget1 className="card-stretch gutter-b"/>

                        </div>
                        <div className="col-lg-6 col-xxl-6">
                            <MixedWidget2 className="card-stretch gutter-b"/>

                        </div>
                    </div>
                    <div className="row bg-light mx-1">
                        <div className="col-md-6 col-xxl-6">
                            <MixedWidget3 className="card-stretch gutter-b"/>

                        </div>
                        <div className="col-lg-6 col-xxl-6">
                            <MixedWidget4 className="card-stretch gutter-b"/>

                        </div>
                    </div>
                    
                </div>
                <div className="col-md-5 col-xxl-6">
                    <div className="col-md-12 py-4">
                        <h3>Ride Statistics</h3>
                    </div>
                    <div className="row bg-light">
                        <div className="col-md-6 col-xxl-6">
                            <MixedWidget1Ride className="card-stretch gutter-b"/>

                        </div>
                        <div className="col-lg-6 col-xxl-6">
                            <MixedWidget2Ride className="card-stretch gutter-b"/>

                        </div>
                    </div>
                    <div className="row bg-light">
                        <div className="col-md-6 col-xxl-6">
                            <MixedWidget3Ride className="card-stretch gutter-b"/>

                        </div>
                        <div className="col-lg-6 col-xxl-6">
                            <MixedWidget4Ride className="card-stretch gutter-b"/>

                        </div>
                    </div>
                    
                </div>
                

                
            </div>
            <div className="row">
                <div className="col-md-6 col-xxl-6">
                    <div className="col-md-12 py-4">
                        <h3>Riders</h3>
                    </div>
                    <div className="row bg-light mx-1">
                        <div className="col-md-6 col-xxl-6 my-auto">
                            <MixedWidget14 className="card-stretch gutter-b" />

                        </div>
                        <div className="col-md-6 col-xxl-6  my-auto">
                            <MixedWidget15 className="card-stretch gutter-b" />

                        </div>
                        
                    </div>
                   
                    
                </div>
                <div className="col-md-6 col-xxl-6">
                    <div className="col-md-12 py-4">
                        <h3>Drivers</h3>
                    </div>
                    <div className="row bg-light mx-1">
                        <div className="col-md-6 col-xxl-6 my-auto">
                            <MixedWidget14Driver className="card-stretch gutter-b" />

                        </div>
                        <div className="col-md-6 col-xxl-6  my-auto">
                            <MixedWidget15Driver className="card-stretch gutter-b" />

                        </div>
                        
                    </div>
                   
                    
                </div>
                
               
                
            </div>
    </>);
}
