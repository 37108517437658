/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function MixedWidget2Ride({ className }) {
  

  

  return (
    <div className={`card card-custom bg-white mt-4 ${className}`}>
      {/* Body */}
      <div className="card-body position-relative overflow-hidden">
        <div className="row justify-content-center">
          <div className="col-md-4 my-auto">
          <FontAwesomeIcon color="#4A63FF" icon="dharmachakra" size="3x"/>

          </div>
          <div className="col-md-8">
            <div className="row">
              <h4>ONGOING RIDES</h4>
            </div>
            <div className="row">
              <h1>135</h1>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  );
}


