import React, { useEffect, useMemo } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/driversActions";
import MUIDataTable from 'mui-datatables';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  toAbsoluteUrl
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../DriversUIHelpers";
import { useDriversUIContext } from "../DriversUIContext";

export function DriverVehicles() {
  // Drivers UI Context
  const driversUIContext = useDriversUIContext();
  const driversUIProps = useMemo(() => {
    return {
      ids: driversUIContext.ids,
      setIds: driversUIContext.setIds,
      queryParams: driversUIContext.queryParams,
      setQueryParams: driversUIContext.setQueryParams,
      openEditDriverDialog: driversUIContext.openEditDriverDialog,
      openDeleteDriverDialog: driversUIContext.openDeleteDriverDialog,
    };
  }, [driversUIContext]);

  const getMuiTheme = () => createTheme({
    overrides: {
      MUIDataTableToolbar: {
        root: {
          background: '#ffffff'
        },
        button:{
          display: 'none'
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          height: 'unset',
          maxHeight: 'none'
        }
      },
      MUIDataTableSearch:{
        clearIcon:{
          display: 'none'
        }
      },

    }
  })

  // Getting curret state of drivers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.drivers }),
    shallowEqual
  );
  const { entities } = currentState;

  // Drivers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    driversUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchDrivers(driversUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driversUIProps.queryParams, dispatch]);

  const dt_columns = [
    {
      name: "id",
      options: {
          filter: false,
          sort: false,
          display: false
      }
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
          filter: true,
          sort: true,
          display: true
      }
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
          filter: true,
          sort: true,
          display: true
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
          filter: true,
          sort: true,
          display: true
      }
    },
    {
      name: "registrationDate",
      label: "Signup Date",
      options: {
          filter: true,
          sort: true,
          display: true
      }
    },
    {
      name: "phone",
      label: "Phone",
      options: {
          filter: true,
          sort: true,
          display: true
      }
    },
    {
      name: "wallet_balance",
      label: "Wallet Balance",
      options: {
          filter: true,
          sort: true,
          display: true,
          customBodyRender: (value, tableMeta) => {

            return (
                <>
                    $ {value.toFixed(2)}
                    <button style={{display:'block'}} className="btn btn-success">Add Balance</button>
                </>
            );
          }
      }
    },
    {
      name: "Documents",
      name: "documents",
      options: {
          filter: true,
          sort: true,
          display: true,
          customBodyRender: (value, tableMeta) => {

            return (
                <>
                <a
                  title="Edit driver"
                  className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                  onClick={() => driversUIProps.openEditDriverDialog(tableMeta.rowData[0])}
                >
                <span className="svg-icon svg-icon-xxl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")}
                  />
                </span>
                </a>
                </>
            );
          }
      }
    },
    {
      name: "accept_cash",
      label: "Accept Cash",
      options: {
          filter: true,
          sort: true,
          display: true,
          customBodyRender: (value, tableMeta) => {
            return (
                <>
                    <i class="display-4 fas {value == 1 ? fa-check-circle text-success : fa-times-circle text-danger }"></i>

                </>
            );
          }
      }
    },
    {
      name: "id",
      label: "Actions",
      options:{
          filter: false,
          sort: false,
          display: true,
          customBodyRender: (value, row) => {
              console.log(row, "Value: ", value);
            return (
                <>
                  <a
                    title="Edit driver"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={() => driversUIProps.openEditDriverDialog(row.rowData[0])}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                      />
                    </span>
                  </a>
                  <></>

                  <a
                    title="Delete driver"
                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                    onClick={() => driversUIProps.openDeleteDriverDialog(value)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                    </span>
                  </a>
                </>
            );
          }
      },
      setCellProps: (value) => {
        return {
          className: 'text-right pr-0'
        };
      },
    },
  ];

  const tbl_options = {
      filterType: 'dropdown',
      responsive: 'scroll',
      download: false,
      print: false,
      selectableRows: false,
      filter: true,
      viewColumns: true,
      searchPlaceholder: "Search"
    };

  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={entities === null ? [] : entities} columns={dt_columns} options={tbl_options} />
      </MuiThemeProvider>

    </>
  );
}
