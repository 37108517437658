import React, {useEffect } from "react";
import {useSubheader} from "../../_metronic/layout";

export function About() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("About Us");

  useEffect(() => {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
  });

  return (<>
      <h1>About Us</h1>

      <p>Allez is a tech start-up company co-founded by Mellissa Preville and Safiya Paul - two young St. Lucian women seeking to provide more accessible and convenient options for persons within the transportation industry. Preville and Paul are both avid travelers and business process improvement professionals, who have combined their expertise in both fields to identify and address long standing areas of improvement related to the ease of movement of people (locals and visitors alike) across St. Lucia.</p>
      <p>The idea to create Allez stemmed from an anticipation of record-breaking numbers visiting the islands’ 2020 Carnival celebrations and the foreseeable chaos which would ensue due to the already frustrating traffic issue in the north (most popular area) of the island. Mellissa and Safiya’s visit to Ghana during the 2019 “Year of the Return” celebrations, solidified the importance of creating a solution ahead of St. Lucia’s expected exponential growth in arrivals. The pair sometimes endured 2+ hours of traffic before events in Ghana, leaving them determined to ensure this was not a reality for visitors to their countries’ shores. Knowing that their proposed solution would also offer much needed relief (even outside of peak seasons), the motivated duo decided to go full throttle with their idea of creating a technological solution which will aid in reducing congestion and air pollution while simultaneously offering much-needed economic growth in St. Lucia. </p>
      <p>Although Allez is in its infancy stages, co-founders Mellissa and Safiya continually discuss and strategize expansion and enhancements to achieve their many goals. With co-owners like these who are committed to the advancement of people, it’s no surprise that Allez is a social enterprise which prides itself on being forward thinking, eco-friendly, employee driven and socially responsible. This foundation creates a clear path towards the company’s ultimate goal of making the “free-movement of people” a practice that all can attain and enjoy across the region. We look forward to serving and growing with you as we face the added challenges brought on by this global pandemic. </p>
      <p>Thank you for trusting us with your travels today and Welcome to the Allez Family!</p>


      </>);
}
