import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../_metronic/_partials/controls";

export function DriverEditDialogHeader({ id }) {
  // Drivers Redux state
  const { driverForEdit, actionsLoading } = useSelector(
    (state) => ({
      driverForEdit: state.drivers.driverForEdit,
      actionsLoading: state.drivers.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = id ? "" : "New Driver";
    if (driverForEdit && id) {
      _title = `Edit driver '${driverForEdit.fname} ${driverForEdit.lname}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [driverForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
