import React from "react";
import { Route } from "react-router-dom";
import { AdminsLoadingDialog } from "./admins-loading-dialog/AdminsLoadingDialog";
import { AdminEditDialog } from "./admin-edit-dialog/AdminEditDialog";
import { AdminDeleteDialog } from "./admin-delete-dialog/AdminDeleteDialog";
import { AdminsDeleteDialog } from "./admins-delete-dialog/AdminsDeleteDialog";
import { AdminsFetchDialog } from "./admins-fetch-dialog/AdminsFetchDialog";
import { AdminsUpdateStateDialog } from "./admins-update-status-dialog/AdminsUpdateStateDialog";
import { AdminsUIProvider } from "./AdminsUIContext";
import { AdminsCard } from "./AdminsCard";

export function AdminsPage({ history }) {
  const adminsUIEvents = {
    newAdminButtonClick: () => {
      history.push("/admins/new");
    },
    openEditAdminDialog: (id) => {
      history.push(`/admins/${id}/edit`);
    },
    openDeleteAdminDialog: (id) => {
      history.push(`/admins/${id}/delete`);
    },
    openDeleteAdminsDialog: () => {
      history.push(`/admins/deleteAdmins`);
    },
    openFetchAdminsDialog: () => {
      history.push(`/admins/fetch`);
    },
    openUpdateAdminsStatusDialog: () => {
      history.push("/admins/updateStatus");
    }
  }

  return (
    <AdminsUIProvider adminsUIEvents={adminsUIEvents}>
      <AdminsLoadingDialog />
      <Route path="/admins/new">
        {({ history, match }) => (
          <AdminEditDialog
            show={match != null}
            onHide={() => {
              history.push("/admins");
            }}
          />
        )}
      </Route>
      <Route path="/admins/:id/edit">
        {({ history, match }) => (
          <AdminEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/admins");
            }}
          />
        )}
      </Route>
      <Route path="/admins/deleteAdmins">
        {({ history, match }) => (
          <AdminsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/admins");
            }}
          />
        )}
      </Route>
      <Route path="/admins/:id/delete">
        {({ history, match }) => (
          <AdminDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/admins");
            }}
          />
        )}
      </Route>
      <Route path="/admins/fetch">
        {({ history, match }) => (
          <AdminsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/admins");
            }}
          />
        )}
      </Route>
      <Route path="/admins/updateStatus">
        {({ history, match }) => (
          <AdminsUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/admins");
            }}
          />
        )}
      </Route>
      <AdminsCard />
    </AdminsUIProvider>
  );
}
